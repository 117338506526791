@font-face {
  font-family: 'Poppins-Regular';
  src: url("./static/fonts/Poppins_Family/Poppins-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("./static/fonts/Poppins_Family/Poppins-Medium.ttf") format("truetype");
  font-weight: bold; }

body {
  font-family: 'Poppins-Regular'; }

.size-44-52 {
  font-size: 44px;
  line-height: 52px; }

.size-36-44 {
  font-size: 36px;
  line-height: 44px; }

.size-32-40 {
  font-size: 32px;
  line-height: 40px; }

.size-28-36 {
  font-size: 28px;
  line-height: 36px; }

.size-24-32 {
  font-size: 24px;
  line-height: 32px; }

.size-18-28 {
  font-size: 18px;
  line-height: 28px; }

.size-18-24 {
  font-size: 18px;
  line-height: 24px; }

.size-16-24 {
  font-size: 16px;
  line-height: 24px; }

.size-16-20 {
  font-size: 16px;
  line-height: 24px; }

.size-14-20 {
  font-size: 14px;
  line-height: 20px; }

.size-14-16 {
  font-size: 14px;
  line-height: 16px; }

.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.bold {
  font-weight: bold; }

.filter-05 {
  filter: brightness(0.5); }

.darken-bg {
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.3); }

.img-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.background-black-03 {
  background-color: rgba(0, 0, 0, 0.3); }

.background-darkest-blue {
  background-color: #39455E; }

.background-primary {
  background-color: #FFFFFF; }

.background-secondary {
  background-color: #F6F6F6; }

.background-cactus-flower {
  background-color: #F58266; }

.background-deep-orange {
  background-color: #D9664F; }

.background-accent-light {
  background-color: #F1F6FF; }

.background-gray {
  background-color: #EEEEEE; }

.background-light-gray {
  background-color: #F6F6F6; }

.background-deep-blue {
  background-color: #586B94; }

.content-primary-black,
.content-primary-black:hover,
.content-primary-black:focus,
.content-primary-black:active {
  color: #000000;
  text-decoration: none; }

.content-primary-white,
.content-primary-white:hover,
.content-primary-white:focus,
.content-primary-white:active {
  color: #FFFFFF;
  text-decoration: none; }

.content-secondary {
  color: #545454; }

.content-tertiary {
  color: #757575; }

.content-accent,
.content-accent:hover {
  color: #586B94; }

.content-dark-gray {
  color: #333333; }

.color-light-gray {
  color: #E2E2E2; }

.color-light-ash {
  color: #ADB4D2; }

.color-ash {
  color: #464A5B; }

.color-dark-lavender {
  color: #5F637A; }

.color-darkest-blue {
  color: #2E364D; }

.border-bottom-transparent {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.light-border {
  border: 1px solid #E2E2E2; }

.light-border-bottom {
  border-bottom: 1px solid #E2E2E2; }

.double-light-border-bottom {
  border-bottom: 2px solid #E2E2E2; }

.border-bottom-accent {
  border-bottom: 2px solid #586B94; }

.border-radius-50 {
  border-radius: 50%; }

.border-radius-9999 {
  border-radius: 9999px; }

.border-radius-48 {
  border-radius: 48px; }

.border-radius-36 {
  border-radius: 36px; }

.border-radius-30 {
  border-radius: 30px; }

.border-radius-16 {
  border-radius: 16px; }

.border-radius-16-0 {
  border-radius: 16px 16px 0px 0px; }

.border-radius-4 {
  border-radius: 4px; }

.border-radius-8-0 {
  border-radius: 8px 8px 0px 0px; }

.border-radius-8 {
  border-radius: 8px; }

.gray-shadow {
  box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.12), 0px 8px 16px -6px rgba(0, 0, 0, 0.08); }

.modal-shadow {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12); }

.limit-to-48 {
  width: 48px;
  height: 48px; }

.limit-to-36 {
  width: 36px;
  height: 36px; }

.max-width-80 {
  max-width: 80%;
  margin: auto; }

.max-width-75 {
  max-width: 75%;
  margin: auto;
  padding: 0px; }

.max-width-50 {
  max-width: 50%;
  margin: auto;
  padding: 0px; }

.size-52x52 {
  width: 52px;
  height: 52px; }

.rounded_logo_with_bg {
  box-sizing: border-box;
  width: 52px;
  height: 52px;
  /* Light Mode / Border / Opaque */
  border: 1px solid #E2E2E2;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%; }

.float_left {
  float: left; }

.first {
  width: 41px; }

.second {
  width: 80px; }

.third {
  width: 12px; }

.add_weight {
  font-weight: 600; }

.object-fit-contain {
  object-fit: contain; }

.width-35 {
  width: 35%;
  margin: auto;
  padding: 0px; }

.width-45 {
  width: 45%;
  margin: auto;
  padding: 0px; }

.width-100 {
  width: 100%; }

.height-100vh {
  height: 100vh; }

.height-100 {
  height: 100%; }

.height-130 {
  height: 130px; }

.height-225 {
  height: 225px; }

.height-35 {
  height: 35vh; }

.height-49 {
  height: 49px; }

.max-modal-height {
  max-height: calc(95vh - 64px); }

.opacity-05 {
  opacity: 0.5; }

.flex-grow-1 {
  flex-grow: 1; }

.modal-title {
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 16px; }

.modal-apply-button,
.modal-apply-button:hover,
.modal-apply-button:active,
.modal-apply-button:focus {
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background: #F58266;
  border-radius: 30px;
  font-family: "Poppins-Medium", sans-serif; }

.modal-cancel-button {
  margin-right: 16px;
  padding: 16px 20px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif; }

.txt-align-left {
  text-align: left; }

.txt-align-right {
  text-align: right; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

.object-fit-cover {
  object-fit: cover; }

.display-flex {
  display: flex;
  padding: 0px; }

.display-contents {
  display: contents; }

.justify-content-space-between {
  justify-content: space-between; }

.justify-content-centered {
  justify-content: center; }

.align-items-center {
  align-items: center; }

.pt-88 {
  padding-top: 88px; }

.pt-65 {
  padding-top: 65px; }

.p-24 {
  padding: 24px; }

.p-24-0 {
  padding: 24px 0px; }

.p-16 {
  padding: 16px; }

.p-16-20 {
  padding: 16px 20px; }

.p-16-64 {
  padding: 16px 64px; }

.p-13 {
  padding: 13px; }

.p-10 {
  padding: 10px; }

.p-10-12 {
  padding: 10px 12px; }

.p-5-15 {
  padding: 5px 15px; }

.p-5-13 {
  padding: 5px 13px; }

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.pt-32 {
  padding-top: 32px; }

.ptb-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.ptb-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.prl-16 {
  padding-right: 16px;
  padding-left: 16px; }

.pr-16 {
  padding-right: 16px; }

.pr-0 {
  padding-right: 0px; }

.pl-5 {
  padding-left: 5px; }

.pb-8 {
  padding-bottom: 8px; }

.p-16-24-32 {
  padding: 16px 24px 32px; }

.p-16-24-122 {
  padding: 16px 24px 122px; }

.p-15-205 {
  padding: 15px 2.5vw; }

.m-t-4 {
  margin-top: 4px; }

.mt-22 {
  margin-top: 22px; }

.mt-40 {
  margin-top: 40px; }

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.m-b-4 {
  margin-bottom: 4px; }

.mb-8 {
  margin-bottom: 8px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-14 {
  margin-bottom: 14px; }

.mb-16 {
  margin-bottom: 16px; }

.mb-24 {
  margin-bottom: 24px; }

.mb-32 {
  margin-bottom: 32px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-48 {
  margin-bottom: 48px; }

.mb-64 {
  margin-bottom: 64px; }

.mr-33vw {
  margin-right: 33vw; }

.mr-32 {
  margin-right: 32px; }

.mr-16 {
  margin-right: 16px; }

.mrl-16 {
  margin-right: 16px;
  margin-left: 16px; }

.m-auto {
  margin: auto; }

.m-24 {
  margin: 24px; }

.absolute-tl-12 {
  position: absolute;
  left: 12px;
  top: 12px; }

.p-fixed {
  position: fixed; }

.p-relative {
  position: relative; }

.bottom-absolute {
  position: absolute !important;
  bottom: 0; }

.bottom-sticky {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0; }

.bottom-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0; }

.top-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }

.sticky-on-scroll {
  position: sticky;
  top: 112px; }

.text-decoration-none {
  text-decoration: none; }

.text-align-center {
  text-align: center; }

.text-align-justify {
  text-align: justify; }

.overflow-y-scroll {
  overflow-y: scroll; }

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999;
  overflow-y: scroll;
  padding-top: 32px; }

.modal-content {
  margin: auto;
  width: 30vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0px;
  overflow-y: scroll;
  max-height: calc(95vh - 64px); }

.modal-title {
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 16px; }

::-webkit-scrollbar {
  width: 4px; }

::-webkit-scrollbar-thumb {
  background-color: #545454 !important;
  cursor: pointer; }

.hide-scroll ::-webkit-scrollbar {
  width: 0px !important; }

.centered-cell {
  text-align: center;
  background-color: #EEE;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  flex: 1 1 100%;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  display: flex; }

.selected.centered-cell {
  background-color: #586B94;
  color: white; }
  .selected.centered-cell .cell-title,
  .selected.centered-cell .cell-subtitle {
    color: white; }

.p-10 {
  padding: 10px 0px; }

.p-45 {
  padding: 45px; }

.centered-cell:not(:last-child) {
  margin-right: 6px; }

.cell-icon {
  text-align: center; }

.cell-title {
  font-size: 18px;
  line-height: 24px; }

.cell-subtitle {
  font-size: 12px;
  line-height: 16px; }

.infoButton,
.infoButton:hover,
.infoButton:active,
.infoButton:focus {
  padding: 10px 12px;
  background-color: #F1F6FF;
  border-radius: 9999px;
  font-size: 14px;
  line-height: 16px;
  color: #586B94;
  max-width: 100%;
  overflow: hidden;
  align-self: flex-start; }

.ctaButton-wrapper {
  position: relative;
  text-align: center;
  left: 0;
  width: 100%;
  border-top: 1px solid #E2E2E2;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ctaButton-enabled,
.ctaButton-enabled:hover,
.ctaButton-enabled:active,
.ctaButton-enabled:focus {
  padding: 16px 20px;
  background: #F58266;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF; }

.ctaButton-width {
  width: 50%; }

.ctaButton-disabled,
.ctaButton-disabled:hover,
.ctaButton-disabled:active,
.ctaButton-disabled:focus {
  padding: 16px 20px;
  background: #F6F6F6;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #757575; }

.tooltip {
  right: 11%;
  left: unset !important;
  width: 40vw !important;
  max-width: unset !important; }

.tooltip.bottom .tooltip-arrow {
  background-color: red;
  border-bottom-color: #0c2b45 !important;
  right: 5%;
  left: unset !important; }

.tooltip .tooltip-inner {
  border-color: 1px solid yellow;
  color: #ffffff;
  background-color: #0c2b45;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px;
  font-family: "Poppins-Medium", sans-serif;
  width: 40vw !important;
  max-width: unset !important;
  text-align: left; }

.gray-input {
  height: 48px;
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: none; }

.gray-input:focus,
input[type="text"]:focus,
.form-control.is-valid,
.form-control.is-valid:focus {
  background-color: #EEEEEE;
  border: 0px;
  box-shadow: none; }

button:focus:not(:focus-visible) {
  box-shadow: none; }

.btn:focus {
  box-shadow: none; }

.smaller-width {
  width: auto;
  padding-right: 0px;
  padding-left: 0px; }

.space-column-right {
  padding-right: calc(var(--bs-gutter-x) * 1.5); }

.special-view-cart-mobile {
  position: fixed;
  bottom: 21px;
  left: 16px;
  right: 16px;
  opacity: 1;
  width: 93vw;
  z-index: 1; }

.overflow-wrap-break-word {
  overflow-wrap: break-word; }

.grayed-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 999999999999; }

.hands-icon {
  width: 50px;
  margin: auto;
  align-self: center;
  justify-self: center; }

.circle-spinner {
  width: 120px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.min-w-73 {
  min-width: 73px; }

.min-w-37 {
  min-width: 37px; }

.main-bg-style {
  height: 50vh;
  background-position: "center";
  background-size: "cover"; }

@media (max-width: 992px) {
  .max-width-80,
  .max-width-75,
  .max-width-50 {
    max-width: 100%;
    margin: auto;
    padding: 16px; }
  .sm-p-0 {
    padding: 0px; }
  .sm-p-16 {
    padding: 16px; }
  .sm-m-16 {
    margin: 16px; }
  .sm-ptb-0-40 {
    padding-top: 0px;
    padding-bottom: 40px; }
  .sm-none {
    display: none; }
  .sm-pt-14 {
    padding-top: 14px; }
  .sm-width-100 {
    width: 100%; }
  .sm-border-radius-0 {
    border-radius: 0px; }
  .sm-max-modal-height {
    max-height: 100%; }
  .sm-mt-0 {
    margin-top: 0px; }
  .sm-mt-88 {
    margin-top: 88px; }
  .sm-p-16-20 {
    padding: 16px 20px; }
  .sm-size-18-24 {
    font-size: 18px;
    line-height: 24px; }
  .sm-mr-0 {
    margin-right: 0; }
  .main-bg-style {
    background-size: auto 100%;
    background-repeat: no-repeat; }
  .height-100vh {
    height: 100%; } }
